/* eslint-disable no-nested-ternary */
import { usePartyAction } from "../../domains/party/hook/usePartyAction";
import Avatar from "../common/Avatar";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

type Props = {
  closeModal: () => void;
  scheduleId: string;
};

export default function ScheduleDetailModal({ closeModal, scheduleId }: Props) {
  const { useGetScheduleInfo, usePostSetScheduleAttend } = usePartyAction();
  const { data } = useGetScheduleInfo(scheduleId);

  const handleSetScheduleAttend = async () => {
    await usePostSetScheduleAttend(scheduleId, "attend");
  };

  const handleSetScheduleNotAttend = async () => {
    await usePostSetScheduleAttend(scheduleId, "absence");
  };

  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = "/images/space/img_detail_default.webp";
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-6 mb-14 text-[24px] font-semibold relative">
      <img
        alt="spaceImage"
        className="object-cover w-full h-[214px] border border-twinworldGray-300"
        src=""
        onError={onError}
      />
      <div className="absolute top-[180px]">{data?.name}</div>
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[520px]"
      title={title}
      close={close}
      closeModal={closeModal}
    >
      {data && (
        <>
          <div className="flex flex-col w-full mt-10 items-start gap-y-2 ml-4">
            <span className="text-[16px] font-semibold">{data.name}</span>
            <span>{data.time}</span>
            <span>{data.space.name}</span>
          </div>
          <div className="flex flex-row justify-center gap-x-4 mt-4">
            <button
              className={`border rounded-full p-4 w-[150px] hover:bg-[#51DB68] hover:text-white ${
                data.myAttend === "attend" && "bg-[#51DB68]"
              }`}
              onClick={handleSetScheduleAttend}
            >
              ATTEND
            </button>
            <button
              className={`border rounded-full p-4 w-[150px] hover:bg-[#FA3F3B] hover:text-white ${
                data.myAttend === "absence" && "bg-[#FA3F3B] text-white"
              }`}
              onClick={handleSetScheduleNotAttend}
            >
              NOT ATTEND
            </button>
          </div>
          <div className="flex flex-col w-full mt-10 items-start">
            <h3 className="text-[15px] ml-4">Invited Users</h3>
            <ul className="w-full no-scrollbar overflow-auto max-h-[210px] flex flex-col gap-y-4 p-4 mt-4">
              {data.member.map((member) => (
                <li
                  key={member.userId}
                  className="w-full flex items-center gap-x-10"
                >
                  <Avatar size="md" src="" />
                  <span className="w-[100px]">{member.nickname}</span>
                  {member.isAttend === "attend" ? (
                    <button className="ml-auto">attend</button>
                  ) : member.isAttend === "absence" ? (
                    <button className="ml-auto">x</button>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </Modal>
  );
}
