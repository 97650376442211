import {
  InfiniteData,
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";

import { useInfiniteScrollRef } from "../../hooks/useInfiniteScrollRef";
import Avatar from "../common/Avatar";

type Member = {
  id: string;
  isPartyOwner: boolean;
  userThumbnailUrl: string;
  nickname: string;
  userId: string;
};

type Props = {
  members: InfiniteData<Array<Member>> | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
};

export default function PrivatePartyMemberList({
  members,
  fetchNextPage,
}: Props) {
  const { targetRef } = useInfiniteScrollRef({ fetchNextPage });

  return (
    <ul className="w-full no-scrollbar overflow-auto flex flex-col gap-y-4 p-4 mt-10">
      {members && members.pages[0].length > 0 ? (
        members.pages.map((page) =>
          page.map((member) => (
            <li
              key={member.userId}
              className="w-full flex items-center gap-x-10 border-b border-b-twinworldGray-500 p-2"
            >
              <Avatar size="lg" src="" />
              <span>{member.nickname}</span>
              <div className="flex ml-auto">
                <button>Allow</button>
              </div>
            </li>
          ))
        )
      ) : (
        <div></div>
      )}
      <div ref={targetRef} />
    </ul>
  );
}
