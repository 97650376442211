// TODO: API 나온 후 변경 필요.
interface DummyData {
  id: string;
  description: string;
  updatedAt: Date;
  handleClick: () => void;
}

const dummyData: DummyData[] = [
  {
    id: "test1",
    description: "A friend invitation came from the user.",
    updatedAt: new Date(),
    handleClick: () => {
      console.log("test1 clicked");
    },
  },
  {
    id: "test2",
    description: "A friend request was accepted by the user.",
    updatedAt: new Date(),
    handleClick: () => {
      console.log("test2 clicked");
    },
  },
  {
    id: "test3",
    description: "Your friend request was rejected by the user.",
    updatedAt: new Date(),
    handleClick: () => {
      console.log("test3 clicked");
    },
  },
  {
    id: "test4",
    description:
      "The party schedule was created on April 8, 2024 at 19:30 p.m.",
    updatedAt: new Date(),
    handleClick: () => {
      console.log("test4 clicked");
    },
  },
  {
    id: "test5",
    description:
      "The party schedule was created on April 8, 2024 at 19:30 p.m.",
    updatedAt: new Date(),
    handleClick: () => {
      console.log("test4 clicked");
    },
  },
  {
    id: "test6",
    description:
      "The party schedule was created on April 8, 2024 at 19:30 p.m.",
    updatedAt: new Date(),
    handleClick: () => {
      console.log("test4 clicked");
    },
  },
];

export default function AlertList() {
  return (
    <ul className="w-[320px] bg-white border rounded-[10px] border-black absolute z-50 right-[10px] top-10 shadow-menu overflow-scroll h-[320px] no-scrollbar">
      {dummyData.map((data) => (
        <li
          className="md:px-[10px] lg:px-[16px] xl:px-[25px] py-2.5 cursor-pointer min-w-[120px] box-border w-full border-b-[1px] flex flex-col"
          key={data.id}
          onClick={data.handleClick}
        >
          <span>{data.description}</span>
          <span className="mt-4 flex ml-auto font-normal text-twinworldGray-325">
            {data.updatedAt.toDateString()}
          </span>
        </li>
      ))}
    </ul>
  );
}
