import useModals from "../hooks/useModals";
import CreatePartyModal from "../modal/CreatePartyModal";
import CreatePartySuccessModal from "../modal/CreatePartySuccessModal";

export default function CreatePartyButton() {
  const { openModal, closeModal } = useModals();

  const handleCreatePartySuccessModal = () => {
    closeModal(CreatePartyModal);
    openModal(CreatePartySuccessModal, {});
  };

  const handleClickCreatePartyBtn = () => {
    // TODO: 링크 변경
    openModal(CreatePartyModal, {
      handleCreatePartySuccessModal,
    });
  };

  return (
    <button
      className={`px-4 py-2 w-15 fixed flex flex-col items-center justify-center right-[10px] 3xl:right-40 bottom-[50px] 
      rounded-full bg-black text-twinworldPrimary-200 border-2 border-black hover:bg-twinworldPrimary-200 hover:text-black hover:border-black hover:border-2`}
      onClick={handleClickCreatePartyBtn}
    >
      Create Party
    </button>
  );
}
