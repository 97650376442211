import { useState } from "react";
import { useLocation } from "react-router-dom";

import MainWrapper from "../components/common/MainWrapper";
import TabMenu from "../components/common/menu/TabMenu";
import { Menu } from "../components/menu/types";
import EditPartyMemberList from "../components/party/EditPartyMemberList";
import EditScheduleList from "../components/party/EditScheduleList";
import PartyEditSection from "../components/party/PartyEditSection";
import PrivatePartyMemberList from "../components/party/PrivatePartyMemberList";
import { usePartyAction } from "../domains/party/hook/usePartyAction";

const MENU_LIST: Menu[] = [
  { label: "party info" },
  { label: "members" },
  { label: "schedules" },
];

export default function EditParty() {
  const [menu, setMenu] = useState("party info");

  const location = useLocation();
  const partyId = location.pathname.split("/")[3];

  const { useGetPartyInfo, useGetRegisteredUserList } = usePartyAction();
  const { data } = useGetPartyInfo(partyId);
  const { data: registeredUserList, fetchNextPage } = useGetRegisteredUserList(
    partyId,
    24,
    0
  );

  return (
    <MainWrapper>
      <div className="flex flex-col items-center justify-center w-full gap-4 xl:gap-8">
        <TabMenu
          menuList={MENU_LIST}
          selectedMenu={menu}
          setSelectedMenu={setMenu}
        />
      </div>

      {menu === "party info" && data && (
        <PartyEditSection
          partyId={partyId}
          partyName={data.partyName}
          isPublic={data.isPublic}
          description={data.description}
        />
      )}
      {menu === "members" &&
        data &&
        (data.isPublic ? (
          <EditPartyMemberList members={data.member} />
        ) : (
          <div className="w-full grid grid-cols-2 gap-x-10 mt-10">
            <EditPartyMemberList members={data.member} />
            <PrivatePartyMemberList
              members={registeredUserList}
              fetchNextPage={fetchNextPage}
            />
          </div>
        ))}
      {menu === "schedules" &&
        data &&
        (data.schedule.length > 0 ? (
          <EditScheduleList schedules={data.schedule} />
        ) : (
          <div className="w-full mt-20">No schedules</div>
        ))}
    </MainWrapper>
  );
}
