import { useMutation, useQueryClient } from "@tanstack/react-query";

import itemApi from "../api";

export const useMyBoxActions = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteFromMyBoxMutateAsync } = useMutation({
    mutationFn: itemApi.deleteFromMyBox,
  });

  const deleteFromMyBox = (itemId: string) => {
    const response = deleteFromMyBoxMutateAsync(itemId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["itemDetails", itemId] });
      },
      onError: (error) => {
        throw error;
      },
    });

    return response;
  };

  const { mutateAsync: saveToMyBoxMutateAsync } = useMutation({
    mutationFn: itemApi.saveToMyBox,
  });

  const saveToMyBox = (itemId: string) => {
    const response = saveToMyBoxMutateAsync(itemId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["itemDetails", itemId] });
      },
      onError: (error) => {
        throw error;
      },
    });

    return response;
  };

  return {
    deleteFromMyBox,
    saveToMyBox,
  };
};
