import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";

import partyApi from "../api";

export const usePartyAction = () => {
  const queryClient = useQueryClient();

  const useGetPartyList = (take: number, skip: number) => {
    const { isPending, data, fetchNextPage, hasNextPage, isError } =
      useInfiniteQuery({
        queryKey: ["partyList", { take, skip }],
        queryFn: ({ pageParam }) => {
          const result = partyApi.getPartyList(take, (pageParam - 1) * take);
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
        placeholderData: keepPreviousData,
      });

    return { isPending, data, fetchNextPage, hasNextPage, isError };
  };

  const useGetPartyInfo = (partyId: string) => {
    const { isLoading, data } = useQuery({
      queryKey: ["partyInfo"],
      queryFn: () => partyApi.getPartyInfo(partyId),
    });

    return { isLoading, data };
  };

  const useGetRecommendPartyList = (take: number, skip: number) => {
    const { isLoading, data } = useQuery({
      queryKey: ["recommendPartyList"],
      queryFn: () => partyApi.getRecommendPartyList(take, skip),
    });

    return { isLoading, data };
  };

  const useGetScheduleInfo = (scheduleId: string) => {
    const { isLoading, data } = useQuery({
      queryKey: ["scheduleInfo"],
      queryFn: () => partyApi.getScheduleInfo(scheduleId),
    });

    return { isLoading, data };
  };

  const useGetPartyMemberList = (
    partyId: string,
    take: number,
    skip: number
  ) => {
    const { isPending, data, fetchNextPage, hasNextPage, isError } =
      useInfiniteQuery({
        queryKey: ["partyMemberList", { take, skip }],
        queryFn: ({ pageParam }) => {
          const result = partyApi.getPartyMemberList(
            partyId,
            take,
            (pageParam - 1) * take
          );
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
        placeholderData: keepPreviousData,
      });

    return { isPending, data, fetchNextPage, hasNextPage, isError };
  };

  const useGetRegisteredUserList = (
    partyId: string,
    take: number,
    skip: number
  ) => {
    const { isPending, data, fetchNextPage, hasNextPage, isError } =
      useInfiniteQuery({
        queryKey: ["registeredUserList", { take, skip }],
        queryFn: ({ pageParam }) => {
          const result = partyApi.getPartyMemberList(
            partyId,
            take,
            (pageParam - 1) * take
          );
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
        placeholderData: keepPreviousData,
      });

    return { isPending, data, fetchNextPage, hasNextPage, isError };
  };

  const postSetScheduleAttend = useMutation({
    mutationFn: ({
      scheduleId,
      isAttend,
    }: {
      scheduleId: string;
      isAttend: "attend" | "absence";
    }) => partyApi.postSetScheduleAttend(scheduleId, isAttend),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partyList"] });
      queryClient.invalidateQueries({ queryKey: ["scheduleInfo"] });
    },
  });

  const usePostSetScheduleAttend = (
    scheduleId: string,
    isAttend: "attend" | "absence"
  ) => {
    const { mutateAsync } = postSetScheduleAttend;
    mutateAsync({ scheduleId, isAttend }).catch((error) => {
      throw error;
    });
  };

  const postSetScheduleNotification = useMutation({
    mutationFn: ({
      scheduleId,
      isAllow,
    }: {
      scheduleId: string;
      isAllow: boolean;
    }) => partyApi.postSetScheduleNotification(scheduleId, isAllow),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partyList"] });
      queryClient.invalidateQueries({ queryKey: ["partyInfo"] });
      queryClient.invalidateQueries({ queryKey: ["scheduleInfo"] });
    },
  });

  const usePostSetScheduleNotification = (
    scheduleId: string,
    isAllow: boolean
  ) => {
    const { mutateAsync } = postSetScheduleNotification;
    mutateAsync({ scheduleId, isAllow }).catch((error) => {
      throw error;
    });
  };

  const postCreateSchedule = useMutation({
    mutationFn: ({
      name,
      time,
      spaceId,
      partyId,
    }: {
      name: string;
      time: number;
      spaceId: string;
      partyId: string;
    }) => partyApi.postCreateSchedule(name, time, spaceId, partyId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partyInfo"] });
      queryClient.invalidateQueries({ queryKey: ["partyList"] });
      queryClient.invalidateQueries({ queryKey: ["scheduleInfo"] });
    },
  });

  const usePostCreateSchedule = (
    name: string,
    time: number,
    spaceId: string,
    partyId: string
  ) => {
    const { mutateAsync } = postCreateSchedule;
    mutateAsync({ name, time, spaceId, partyId }).catch((error) => {
      throw error;
    });
  };

  const postCreateParty = useMutation({
    mutationFn: ({
      name,
      description,
      isPublic,
    }: {
      name: string;
      description: string;
      isPublic: boolean;
    }) => partyApi.postCreateParty(name, description, isPublic),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partyList"] });
      queryClient.invalidateQueries({ queryKey: ["scheduleInfo"] });
    },
  });

  const usePostCreateParty = (
    name: string,
    description: string,
    isPublic: boolean
  ) => {
    const { mutateAsync } = postCreateParty;
    mutateAsync({ name, description, isPublic }).catch((error) => {
      throw error;
    });
  };

  const postRegisterParty = useMutation({
    mutationFn: (partyId: string) => partyApi.postRegisterParty(partyId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partyList"] });
    },
  });

  const usePostRegistryParty = (partyId: string) => {
    const { mutateAsync } = postRegisterParty;
    mutateAsync(partyId).catch((error) => {
      throw error;
    });
  };

  const patchEditParty = useMutation({
    mutationFn: ({
      partyId,
      name,
      description,
      isPublic,
    }: {
      partyId: string;
      name: string;
      description: string;
      isPublic: boolean;
    }) => partyApi.patchEditParty(partyId, name, description, isPublic),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partyList"] });
      queryClient.invalidateQueries({ queryKey: ["partyInfo"] });
    },
  });

  const usePatchEditParty = async ({
    partyId,
    name,
    description,
    isPublic,
  }: {
    partyId: string;
    name: string;
    description: string;
    isPublic: boolean;
  }) => {
    const { mutateAsync } = patchEditParty;
    await mutateAsync({ partyId, name, description, isPublic }).catch(
      (error) => {
        throw error;
      }
    );
  };

  const patchResignPartyMember = useMutation({
    mutationFn: ({ partyId, userId }: { partyId: string; userId: string }) =>
      partyApi.patchResignPartyMember({ partyId, userId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partyList"] });
      queryClient.invalidateQueries({ queryKey: ["partyInfo"] });
    },
  });

  const usePatchResignPartyMember = async ({
    partyId,
    userId,
  }: {
    partyId: string;
    userId: string;
  }) => {
    const { mutateAsync } = patchResignPartyMember;
    await mutateAsync({ partyId, userId }).catch((error) => {
      throw error;
    });
  };

  const deleteSchedule = useMutation({
    mutationFn: ({ scheduleId }: { scheduleId: string }) =>
      partyApi.deleteSchedule(scheduleId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partyList"] });
      queryClient.invalidateQueries({ queryKey: ["partyInfo"] });
    },
  });

  const useDeleteSchedule = async (scheduleId: string) => {
    const { mutateAsync } = deleteSchedule;
    await mutateAsync({ scheduleId }).catch((error) => {
      throw error;
    });
  };

  return {
    useGetPartyList,
    useGetPartyInfo,
    useGetRecommendPartyList,
    useGetScheduleInfo,
    useGetPartyMemberList,
    useGetRegisteredUserList,
    usePostSetScheduleAttend,
    usePostSetScheduleNotification,
    usePostCreateSchedule,
    usePostCreateParty,
    usePostRegistryParty,
    usePatchResignPartyMember,
    usePatchEditParty,
    useDeleteSchedule,
  };
};
