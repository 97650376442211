import { FieldValues, useForm } from "react-hook-form";

import { usePartyAction } from "../../domains/party/hook/usePartyAction";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  partyId: string;
};

export default function CreateScheduleModal({
  isOpen,
  closeModal,
  partyId,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      time: "",
    },
  });

  const { usePostCreateSchedule } = usePartyAction();

  const isInputValid =
    Object.keys(dirtyFields).length > 0 && Object.keys(errors).length === 0;

  // TODO: api 연동 필요.
  const onSubmit = async (data: FieldValues) => {
    if (!isInputValid) {
      return;
    }

    const spaceId = "01H403Z5MHWQM48V1HG7KP5KX1"; // 임시값

    const { name, time } = data;

    try {
      await usePostCreateSchedule(name, time, spaceId, partyId);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-[30px] md:mt-[60px] mb-[26px] md:mb-[42px] text-[20px] md:text-[24px] font-semibold">
      Create Schedule
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[339px] md:max-w-[480px]"
      isOpen={isOpen}
      title={title}
      close={close}
      closeModal={closeModal}
    >
      <div className="w-full h-[214px] md:h-auto">
        <form
          className="flex flex-col items-center gap-4 mx-20 mb-14"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            className={`py-[16px] px-[16px] md:px-7 rounded-full text-[16px] font-normal w-full box-border border ${
              errors?.name
                ? "text-twinworldRed border-twinworldRed"
                : "border-twinworldGray-350 focus:border-twinworldGray-770"
            } `}
            type="text"
            placeholder="Enter schedule name"
            maxLength={40}
            {...register("name", {
              required: true,
              maxLength: {
                value: 40,
                message: "* Schedule name should be less than 40 letters",
              },
            })}
            id="name"
          />
          <input
            className={`py-[16px] px-[16px] md:px-7 rounded-full text-[16px] font-normal w-full box-border border ${
              errors?.time
                ? "text-twinworldRed border-twinworldRed"
                : "border-twinworldGray-350 focus:border-twinworldGray-770"
            } `}
            type="datetime-local"
            id="time"
            {...register("time", { required: true })}
          />
          <button
            type="submit"
            className="flex items-center justify-center w-[320px] h-[58px] px-[80px] py-[21px] border-2 border-black bg-twinworldPrimary-200 rounded-full mt-[64px] text-[16px] font-semibold hover:bg-black hover:text-twinworldPrimary-200 
          disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
            disabled={!isInputValid}
          >
            Create Schedule
          </button>
        </form>
      </div>
    </Modal>
  );
}
