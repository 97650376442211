import { PartyInfo } from "../interface";

import PartyMemberModel from "./PartyMember";

class PartyInfoModel {
  partyId;

  partyName;

  isPublic;

  ownerId;

  description;

  member;

  schedule;

  constructor(partyInfo: PartyInfo) {
    this.partyId = partyInfo.id;
    this.partyName = partyInfo.name;
    this.isPublic = partyInfo.isPublic;
    this.ownerId = partyInfo.ownerId;
    this.description = partyInfo.description;
    this.member = partyInfo.member.map(
      (member) => new PartyMemberModel(member)
    );
    this.schedule = partyInfo.schedule;
  }
}

export default PartyInfoModel;
