import { useFriendActions } from "../../domains/friend/hook/useFriendActions";
import { Friend } from "../../domains/friend/interface";
import FriendCard from "../card/FriendCard";
import ListWrapper from "../common/ListWrapper";

type FriendCard = Friend & { userImageUrl: string };

type Props = {
  friendList: Array<Omit<FriendCard, "friendImageMediaId">>;
};

export default function RecommendFriendList({ friendList }: Props) {
  const { usePostFriendRequest } = useFriendActions();

  return (
    <section className="flex min-w-[339px] w-full px-[18px] md:px-[24px] lg:px-[32px] xl:px-0 flex-col gap-0 mx-auto">
      <h2 className="text-[45px] font-semibold mt-[60px]">Recommend Friends</h2>
      <div className="mt-10">
        <ListWrapper isEmptyData={!friendList.length}>
          {friendList.map(
            ({ friendId, nickname, userImageUrl, serviceName, gender }) => (
              <FriendCard
                key={friendId}
                friendId={friendId}
                nickname={nickname}
                userImageUrl={userImageUrl}
                serviceName={serviceName}
                gender={gender}
              >
                <button
                  className="bg-twinworldGray-650 text-gray-100 px-5 py-3 font-semibold rounded-full mt-2"
                  onClick={() => {
                    usePostFriendRequest(friendId);
                  }}
                >
                  Friend Request
                </button>
              </FriendCard>
            )
          )}
        </ListWrapper>
      </div>
    </section>
  );
}
