import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import CreatePartyButton from "../components/button/CreatePartyButton";
import MainWrapper from "../components/common/MainWrapper";
import TabMenu from "../components/common/menu/TabMenu";
import { Menu } from "../components/menu/types";
import CreatePartySuccessModal from "../components/modal/CreatePartySuccessModal";
import MyPartyList from "../components/party/MyPartyList";
import RecommendPartyList from "../components/party/RecommendPartyList";
import { usePartyAction } from "../domains/party/hook/usePartyAction";

const MENU_LIST: Menu[] = [
  { label: "recommend party list" },
  { label: "my party list" },
];

type Location = {
  state: {
    menu: string;
  };
};

export default function Party() {
  const { state } = useLocation() as Location;
  const [menu, setMenu] = useState("recommend party list");
  const [isCreatePartySuccess, setIsCreatePartySuccess] = useState(false);

  const { useGetRecommendPartyList, useGetPartyList } = usePartyAction();
  const { data: recommendPartyList } = useGetRecommendPartyList(24, 0);
  const { data: myPartyList, fetchNextPage } = useGetPartyList(24, 0);

  useEffect(() => {
    if (state) {
      setMenu(state.menu);
    }
  }, [state]);

  return (
    <MainWrapper>
      <div className="flex items-center w-full">
        <div className="flex flex-col items-center justify-center w-full gap-8">
          <TabMenu
            menuList={MENU_LIST}
            selectedMenu={menu}
            setSelectedMenu={setMenu}
          />
        </div>
      </div>
      <section className="w-full xl:min-w-[1440px] xl:mx-auto mt-10">
        {menu === "recommend party list" && recommendPartyList && (
          <RecommendPartyList recommendPartyList={recommendPartyList} />
        )}
        {menu === "my party list" && myPartyList && (
          <MyPartyList
            myPartyList={myPartyList}
            fetchNextPage={fetchNextPage}
          />
        )}
      </section>
      <CreatePartyButton />
      {isCreatePartySuccess && (
        <CreatePartySuccessModal
          closeModal={() => setIsCreatePartySuccess(false)}
        />
      )}
    </MainWrapper>
  );
}
