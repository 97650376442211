import { PartyList } from "../../domains/party/interface";
import PartyCard from "../card/PartyCard";
import ListWrapper from "../common/ListWrapper";

type Props = {
  recommendPartyList: PartyList[];
};

export default function RecommendPartyList({ recommendPartyList }: Props) {
  return (
    <section className="flex min-w-[339px] w-full px-[18px] md:px-[24px] lg:px-[32px] xl:px-0 flex-col gap-0 mx-auto">
      <h2 className="text-[45px] font-semibold mt-[60px]">
        Recommend Party List
      </h2>
      <ListWrapper>
        {recommendPartyList.map((data) => (
          <PartyCard
            key={data.id}
            partyId={data.id}
            partyName={data.name}
            memberCount={data.memberCount}
            scheduleCount={data.scheduleCount}
            lastEditTime={data.updatedAt}
          />
        ))}
      </ListWrapper>
    </section>
  );
}
