import { Switch } from "@headlessui/react";

type Props = {
  isToggle: boolean;
  handleToggle: () => void;
};

export default function ToggleButton({ isToggle, handleToggle }: Props) {
  return (
    <Switch
      checked={isToggle}
      onChange={handleToggle}
      className={`${
        isToggle ? "bg-blue-600" : "bg-gray-200"
      } relative inline-flex h-10 w-20 items-center rounded-full`}
    >
      <span
        className={`${
          isToggle ? "translate-x-11" : "translate-x-1"
        } inline-block h-8 w-8 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
}
