import { client } from "../../utils/axiosInstance";

import { ScheduleInfo } from "./interface";
import PartyInfoModel from "./model/PartyInfo";
import ScheduleInfoDto from "./model/ScheduleInfo";

const getPartyList = async (take: number, skip: number) => {
  const response = await client({
    method: "GET",
    url: `/sns/party?take=${take}&skip=${skip}`,
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data.rows;
};

const getPartyInfo = async (partyId: string): Promise<PartyInfoModel> => {
  const response = await client({
    method: "GET",
    url: `/sns/party/${partyId}`,
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return new PartyInfoModel(response.data);
};

const getRecommendPartyList = async (take: number, skip: number) => {
  const response = await client({
    method: "GET",
    url: `/sns/party/recommended?take=${take}&skip=${skip}`,
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data.rows;
};

const getScheduleInfo = async (scheduleId: string) => {
  const response = await client({
    method: "GET",
    url: `/sns/party/schedule/${scheduleId}`,
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return new ScheduleInfoDto(response.data);
};

const getPartyMemberList = async (
  partyId: string,
  take: number,
  skip: number
) => {
  const response = await client({
    method: "GET",
    url: `/sns/party/${partyId}/member?take=${take}&skip=${skip}`,
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data;
};

const getRegisteredUserList = async (
  partyId: string,
  take: number,
  skip: number
) => {
  const response = await client({
    method: "GET",
    url: `/sns/party/${partyId}/registered-member?take=${take}&skip=${skip}`,
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data;
};

const postSetScheduleAttend = async (
  scheduleId: string,
  isAttend: "attend" | "absence"
) => {
  await client({
    method: "POST",
    url: `/sns/party/schedule/${scheduleId}/attend`,
    data: {
      isAttend,
    },
  }).catch((error) => {
    throw error;
  });
};

const postSetScheduleNotification = async (
  scheduleId: string,
  isAttend: boolean
) => {
  await client({
    method: "POST",
    url: `/schedule/notification?scheduleId=${scheduleId}&isAttend=${isAttend}`,
  }).catch((error) => {
    throw error;
  });
};

const postCreateSchedule = async (
  name: string,
  time: number,
  spaceId: string,
  partyId: string
) => {
  await client({
    method: "POST",
    url: `/sns/party/${partyId}/schedule`,
    data: {
      name,
      time,
      spaceId,
    },
  }).catch((error) => {
    throw error;
  });
};

// TODO: thumbnail image formdata
const postCreateParty = async (
  name: string,
  description: string,
  isPublic: boolean
) => {
  await client({
    method: "POST",
    url: `/sns/party`,
    data: {
      name,
      description,
      isPublic,
    },
  }).catch((error) => {
    throw error;
  });
};

const postRegisterParty = async (partyId: string) => {
  await client({
    method: "POST",
    url: `/sns/party/${partyId}/apply`,
  }).catch((error) => {
    throw error;
  });
};

const postAllowNewPatyMember = async (
  partyMemberIdOrIds: string | string[]
) => {
  await client({
    method: "POST",
    url: `/sns/party/allow-member`,
    data: {
      partyMemberIdOrIds,
    },
  }).catch((error) => {
    throw error;
  });
};

const patchResignPartyMember = async ({
  partyId,
  userId,
}: {
  partyId: string;
  userId: string;
}) => {
  await client({
    method: "PATCH",
    url: `/sns/party/${partyId}/member/${userId}`,
  }).catch((error) => {
    throw error;
  });
};

// TODO: thumbnail image formdata
const patchEditParty = async (
  partyId: string,
  name: string,
  description: string,
  isPublic: boolean
) => {
  await client({
    method: "PATCH",
    url: `/sns/party/${partyId}`,
    data: {
      name,
      description,
      isPublic,
    },
  }).catch((error) => {
    throw error;
  });
};

const deleteSchedule = async (scheduleId: string) => {
  await client({
    method: "DELETE",
    url: `/sns/party/schedule/${scheduleId}`,
  }).catch((error) => {
    throw error;
  });
};

const partyApi = {
  getPartyList,
  getPartyInfo,
  getRecommendPartyList,
  getScheduleInfo,
  getPartyMemberList,
  getRegisteredUserList,
  postSetScheduleAttend,
  postSetScheduleNotification,
  postCreateSchedule,
  postCreateParty,
  postRegisterParty,
  postAllowNewPatyMember,
  patchResignPartyMember,
  patchEditParty,
  deleteSchedule,
};

export default partyApi;
