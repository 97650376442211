import { ScheduleMember } from "../interface";

class ScheduleMemberDto {
  id;

  nickname;

  userId;

  isAttend;

  imageThumbnailUrl;

  constructor(data: ScheduleMember) {
    this.id = data.id;
    this.nickname = data.nickname;
    this.userId = data.userId;
    this.imageThumbnailUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${data.memberImageMediaId}?size=original&fileType=profile`;
    this.isAttend = data.isAttend;
  }
}

export default ScheduleMemberDto;
