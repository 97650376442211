import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { Friend } from "../../domains/friend/interface";
import Avatar from "../common/Avatar";

type Props = Omit<Friend, "friendImageMediaId"> &
  PropsWithChildren & {
    userImageUrl: string;
  };

export default function FriendCard({
  nickname,
  serviceName,
  friendId,
  gender,
  userImageUrl,
  children,
}: Props) {
  const navigate = useNavigate();

  const handleClickAvatar = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/user/${friendId}`);
  };

  return (
    <div
      className="flex flex-col w-full px-4 xl:px-0 xl:w-[248px] xl:min-h-[350px] xl:h-fit cursor-pointer border box-border py-6 border-gray-100 duration-300 hover:-translate-y-1 shadow"
      key={friendId}
    >
      <div className="flex flex-col items-center gap-4 w-full h-full">
        <Avatar size="2xl" src={userImageUrl} onClick={handleClickAvatar} />
        <div className="flex flex-col gap-y-2 items-center mt-4">
          <p className="text-twinworldGray-700 font-bold">{nickname}</p>
          <p>{serviceName}</p>
          <p>{gender ? gender.toLocaleUpperCase() : "UnKnown"}</p>
          {children}
        </div>
      </div>
    </div>
  );
}
