import { client } from "../../utils/axiosInstance";

import { Friend } from "./interface";
import FriendCard from "./model/FriendCard";
import FriendRequest from "./model/FriendRequest";

type FriendListCardType = Omit<Friend, "friendImageMediaId"> & {
  userImageUrl: string;
};

type FriendRequestType = Friend & {
  id: string;
  userId: string;
  status: "pending" | "approved";
  createdAt: Date;
};

const getFriendList = async (
  take: number,
  skip: number
): Promise<Omit<FriendCard, "friendImageMediaId">[]> => {
  const response = await client({
    url: `/sns/friends?take=${take}&skip=${skip}`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const friendList = response.data.rows.map(
    (friend: Friend) => new FriendCard(friend)
  );

  return friendList;
};

const getReceivedFriendRequestList = async (take: number, skip: number) => {
  const response = await client({
    url: `/sns/friends/received?take=${take}&skip=${skip}`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const receivedFriendList = response.data.rows.map(
    (friend: FriendRequestType) => new FriendRequest(friend)
  );

  return receivedFriendList;
};

const getSentFriendRequestList = async (take: number, skip: number) => {
  const response = await client({
    url: `/sns/friends/sent?take=${take}&skip=${skip}`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const sendFriendList = response.data.rows.map(
    (friend: FriendRequestType) => new FriendRequest(friend)
  );

  return sendFriendList;
};

const getRecommendFriendList = async (): Promise<FriendListCardType[]> => {
  const response = await client({
    url: "/sns/friends/recommend",
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const recommendFriendList = response.data.map(
    (friend: Friend) => new FriendCard(friend)
  );

  return recommendFriendList;
};

const postFriendRequest = async (friendId: string): Promise<void> => {
  await client({
    url: `/sns/friends/${friendId}`,
    method: "POST",
  }).catch((error) => {
    throw error;
  });
};

const postApproveFriendRequest = async (
  friendRequestId: string
): Promise<void> => {
  await client({
    url: `/sns/friends/${friendRequestId}/approve`,
    method: "POST",
  }).catch((error) => {
    throw error;
  });
};

const cancelFriendRequest = async (friendRequestId: string): Promise<void> => {
  await client({
    url: `/sns/friends/${friendRequestId}/cancel`,
    method: "DELETE",
  }).catch((error) => {
    throw error;
  });
};

const deleteFriends = async (friendId: string): Promise<Friend> => {
  const response = await client({
    url: `/sns/friends/${friendId}`,
    method: "DELETE",
  }).catch((error) => {
    throw error;
  });

  return response.data;
};

const friendApi = {
  getFriendList,
  getReceivedFriendRequestList,
  getSentFriendRequestList,
  getRecommendFriendList,
  postFriendRequest,
  postApproveFriendRequest,
  cancelFriendRequest,
  deleteFriends,
};

export default friendApi;
