import Avatar from "../common/Avatar";

type Member = {
  id: string;
  isPartyOwner: boolean;
  userThumbnailUrl: string;
  nickname: string;
  userId: string;
};

type Props = {
  members: Member[];
};

export default function PartyMemberList({ members }: Props) {
  return (
    <ul className="w-full no-scrollbar overflow-auto max-h-[500px] flex flex-col gap-y-4 border p-4">
      {members.map((member) => (
        <li key={member.userId} className="w-full flex items-center gap-x-10">
          <Avatar size="lg" src="" />
          <span>{member.nickname}</span>
          <div className="flex ml-auto">
            {member.isPartyOwner ? "Owner" : ""}
          </div>
        </li>
      ))}
    </ul>
  );
}
