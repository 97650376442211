import { Friend } from "../interface";

class FriendCard {
  nickname;

  serviceName;

  friendId;

  gender;

  userImageUrl;

  constructor(data: Friend) {
    const { nickname, serviceName, friendId, gender, friendImageMediaId } =
      data;

    this.nickname = nickname;
    this.serviceName = serviceName;
    this.friendId = friendId;
    this.gender = gender;
    this.userImageUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${friendImageMediaId}?size=original&fileType=profile`;
  }
}

export default FriendCard;
