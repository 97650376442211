import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";

import friendApi from "../api";

export const useFriendActions = () => {
  const queryClient = useQueryClient();

  const useFriendList = (take: number, skip: number) => {
    const { isPending, data, fetchNextPage, hasNextPage, isError } =
      useInfiniteQuery({
        queryKey: ["friendlist", { take, skip }],
        queryFn: ({ pageParam }) => {
          const result = friendApi.getFriendList(take, (pageParam - 1) * take);
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
        placeholderData: keepPreviousData,
      });

    return { isPending, data, fetchNextPage, hasNextPage, isError };
  };

  const useReceivedFriendRequestList = (take: number, skip: number) => {
    const { isPending, data, fetchNextPage, hasNextPage, isError } =
      useInfiniteQuery({
        queryKey: ["receivedFriendRequestList", { take, skip }],
        queryFn: ({ pageParam }) => {
          const result = friendApi.getReceivedFriendRequestList(
            take,
            (pageParam - 1) * take
          );
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
        placeholderData: keepPreviousData,
      });

    return { isPending, data, fetchNextPage, hasNextPage, isError };
  };

  const useSentFriendRequestList = (take: number, skip: number) => {
    const { isPending, data, fetchNextPage, hasNextPage, isError } =
      useInfiniteQuery({
        queryKey: ["sentFriendRequestList", { take, skip }],
        queryFn: ({ pageParam }) => {
          const result = friendApi.getSentFriendRequestList(
            take,
            (pageParam - 1) * take
          );
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
        placeholderData: keepPreviousData,
      });

    return { isPending, data, fetchNextPage, hasNextPage, isError };
  };

  const useRecommendFriendList = () => {
    const { isLoading, data } = useQuery({
      queryKey: ["recommendFriendList"],
      queryFn: () => friendApi.getRecommendFriendList(),
    });

    return { isLoading, data };
  };

  const postFriendRequest = useMutation({
    mutationFn: (friendId: string) => friendApi.postFriendRequest(friendId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sentFriendRequestList"] });
      queryClient.invalidateQueries({ queryKey: ["recommendFriendList"] });
    },
  });

  const usePostFriendRequest = async (friendId: string) => {
    const { mutateAsync } = postFriendRequest;
    await mutateAsync(friendId).catch((error) => {
      throw error;
    });
  };

  const approveFriendRequest = useMutation({
    mutationFn: (friendRequestId: string) =>
      friendApi.postApproveFriendRequest(friendRequestId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["receivedFriendRequestList"],
      });
    },
  });

  const usePostApproveFriendRequest = async (friendRequestId: string) => {
    const { mutateAsync } = approveFriendRequest;
    await mutateAsync(friendRequestId).catch((error) => {
      throw error;
    });
  };

  const deleteFriend = useMutation({
    mutationFn: (friendId: string) => friendApi.deleteFriends(friendId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["friendlist"] });
    },
  });

  const useDeleteFriend = async (friendId: string) => {
    const { mutateAsync } = deleteFriend;
    await mutateAsync(friendId).catch((error) => {
      throw error;
    });
  };

  const cancelFriendRequest = useMutation({
    mutationFn: (friendRequestId: string) =>
      friendApi.cancelFriendRequest(friendRequestId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sentFriendRequestList"] });
    },
  });

  const useCancelFriendRequest = async (friendRequestId: string) => {
    const { mutateAsync } = cancelFriendRequest;
    await mutateAsync(friendRequestId).catch((error) => {
      throw error;
    });
  };

  return {
    useFriendList,
    useReceivedFriendRequestList,
    useSentFriendRequestList,
    useRecommendFriendList,
    usePostFriendRequest,
    usePostApproveFriendRequest,
    useDeleteFriend,
    useCancelFriendRequest,
  };
};
