import { useLocation } from "react-router-dom";

import MainWrapper from "../components/common/MainWrapper";
import useModals from "../components/hooks/useModals";
import CreateScheduleModal from "../components/modal/CreateScheduleModal";
import PartyInfo from "../components/party/PartyInfo";
import PartyMemberList from "../components/party/PartyMemberList";
import ScheduleList from "../components/party/ScheduleList";
import { usePartyAction } from "../domains/party/hook/usePartyAction";

export default function PartyDetail() {
  const location = useLocation();
  const partyId = location.pathname.split("/")[2];

  const { useGetPartyInfo } = usePartyAction();
  const { data } = useGetPartyInfo(partyId);

  const { openModal } = useModals();

  const handleClickCreateScheduleBtn = () => {
    openModal(CreateScheduleModal, {
      partyId,
    });
  };

  return (
    <MainWrapper>
      {data && (
        <>
          <PartyInfo
            partyId={partyId}
            partyName={data.partyName}
            isPublic={data.isPublic}
            description={data.description}
            ownerId={data.ownerId}
            ownerNickName={
              data.member.filter((m) => m.userId === data.ownerId)[0].nickname
            }
          />
          <div className="w-full grid grid-cols-2 gap-x-10 mt-10">
            <div className="flex flex-col">
              <h3 className="mb-10 font-semibold text-xl">Members</h3>
              <PartyMemberList members={data.member} />
            </div>
            <div className="flex flex-col">
              <div className="flex w-full items-center mb-10">
                <h3 className="font-semibold text-xl">Schedules</h3>
                <button
                  className="ml-auto px-4 py-2 w-15 flex flex-col items-center justify-center right-[10px] 3xl:right-40 bottom-[50px] 
                rounded-full bg-black text-twinworldPrimary-200 border-2 border-black hover:bg-twinworldPrimary-200 hover:text-black hover:border-black hover:border-2"
                  onClick={handleClickCreateScheduleBtn}
                >
                  Create Schedule
                </button>
              </div>
              {data.schedule.length > 0 ? (
                <ScheduleList schedules={data.schedule} />
              ) : (
                <div className="w-full">No schedules</div>
              )}
            </div>
          </div>
        </>
      )}
    </MainWrapper>
  );
}
