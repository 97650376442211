import { ScheduleInfo } from "../interface";

import ScheduleMemberDto from "./ScheduleMember";

class ScheduleInfoDto {
  id;

  member;

  myAttend;

  myNotification;

  name;

  ownerId;

  space;

  time;

  constructor(data: ScheduleInfo) {
    this.id = data.id;
    this.name = data.name;
    this.ownerId = data.ownerId;
    this.myNotification = data.myNotification;
    this.space = data.space;
    this.member = data.members.map((member) => new ScheduleMemberDto(member));
    this.time = data.time;
    this.myAttend = data.myAttend;
  }
}

export default ScheduleInfoDto;
