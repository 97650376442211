import useModals from "../hooks/useModals";
import ScheduleDetailModal from "../modal/ScheduleDetailModal";

type Schedule = {
  id: string;
  name: string;
  time: string;
};

type Props = {
  schedules: Schedule[];
};

export default function ScheduleList({ schedules }: Props) {
  const { openModal } = useModals();

  const handleClickMoreBtn = (scheduleId: string) => {
    openModal(ScheduleDetailModal, { scheduleId });
  };

  return (
    <ul className="w-full no-scrollbar overflow-auto max-h-[500px] flex flex-col gap-y-4 border p-4">
      {schedules.map((schedule) => (
        <li key={schedule.id} className="w-full flex items-center gap-x-10 p-4">
          <span className="w-[150px]">{schedule.name}</span>
          <span>{schedule.time}</span>
          <button
            className="ml-auto cursor-pointer"
            onClick={() => handleClickMoreBtn(schedule.id)}
          >
            More
          </button>
        </li>
      ))}
    </ul>
  );
}
