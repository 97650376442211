import { useNavigate } from "react-router-dom";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { UserInfo } from "../../domains/user/interface";
import Avatar from "../common/Avatar";
import useModals from "../hooks/useModals";
import QRSignInModal from "../modal/QRSignInModal";

type Props = {
  profile: UserInfo;
  closeMenu: () => void;
};

export default function UserMenuList({ profile, closeMenu }: Props) {
  const navigate = useNavigate();
  const { signOut } = useAuthActions();

  const { openModal } = useModals();

  const handleOpenQRModal = () => {
    openModal(QRSignInModal, {});
  };

  const SETTING_MENU_LIST = [
    {
      key: "profile",
      label: "Profile",
      action: () => navigate("/myprofile", { state: { menu: "home" } }),
    },
    {
      key: "pinCode",
      label: "Pin Code",
      action: () => {
        handleOpenQRModal();
      },
    },
  ];

  const MENU_LIST = [
    {
      key: "mySpace",
      label: "My Space",
      action: () => navigate("/myprofile", { state: { menu: "spaces" } }),
    },
    {
      key: "myItem",
      label: "My Item",
      action: () => navigate("/myprofile", { state: { menu: "items" } }),
    },
    {
      key: "mycoins",
      label: "My Coins",
      action: () => navigate("/mycoins", { state: { menu: "redeem" } }),
    },
    {
      key: "friend",
      label: "Friends",
      action: () => navigate("/friends", { state: { menu: "recommend list" } }),
    },
    {
      key: "party",
      label: "Party",
      action: () =>
        navigate("/party", { state: { menu: "recommend party list" } }),
    },
    {
      key: "playSet",
      label: "Play Set",
      action: () => navigate("/myprofile", { state: { menu: "play set" } }),
    },
    {
      key: "avatar",
      label: "Avatar",
      action: () => navigate("/setting", { state: { menu: "avatar" } }),
    },
    {
      key: "setting",
      label: "Settings",
      action: () => navigate("/setting", { state: { menu: "profile" } }),
    },
  ];

  return (
    <div className="flex flex-col items-center justify-end w-[280px] py-[28px] bg-white border rounded-[10px] border-black absolute z-50 right-[60px] -top-[10px] shadow-menu">
      <div className="absolute w-[25px] top-[24px] -right-[25px] z-10 overflow-hidden">
        <div className="w-[13px] h-[30px] origin-top-left transform rotate-45 bg-white border border-black shadow-menu" />
      </div>

      <Avatar size="xl" src={profile.avatarImageUrl} />
      <div className="flex flex-col items-center mt-[12px] gap-[7px] leading-[22px]">
        <span
          className="text-[18px] text-twinworldGray-800 max-w-[220px] truncate"
          key={profile.nickname}
        >
          {profile.nickname}
        </span>
      </div>
      <div className="flex flex-row gap-2.5 mt-5 font-medium">
        {SETTING_MENU_LIST.map((link) => (
          <button
            className="box-border px-[20px] py-2.5 rounded-full border border-twinworldGray-250 bg-twinworldGray-150 text-[14px] font-medium hover:transition-background-color hover:duration-300 hover:bg-black hover:text-white"
            key={link.key}
            onClick={link.action}
          >
            {link.label}
          </button>
        ))}
      </div>
      <div className="flex flex-col w-full gap-2.5 pl-[38px] mt-[25px] py-[25px] text-twinworldGray-900 text-[14px] leading-[13px] font-normal border-y border-y-twinworldGray-200">
        {MENU_LIST.map((link) => (
          <div
            key={link.key}
            className="cursor-pointer w-max hover:border-b-2 hover:border-b-black hover:duration-100 h-[20px] flex items-center"
          >
            <button
              className="hover:-translate-y-0.5 hover:transition-transform hover:duration-200 pb-[2.5px]"
              type="button"
              onClick={() => {
                link.action();
                closeMenu();
              }}
            >
              {link.label}
            </button>
          </div>
        ))}
      </div>
      <div className="flex flex-col w-full pt-[18px] pl-[38px] text-[14px] leading-[13px] font-normal text-twinworldGray-900">
        <div
          className="cursor-pointer w-max hover:border-b-2 hover:border-b-black hover:duration-100 h-[20px]"
          onClick={signOut}
        >
          <div className="hover:-translate-y-0.5 hover:transition-transform hover:duration-200 pb-[5px]">
            Sign Out
          </div>
        </div>
      </div>
    </div>
  );
}
