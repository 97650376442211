import { useNavigate } from "react-router-dom";

import Image from "../common/Image";

type Props = {
  partyId: string;
  partyName: string;
  memberCount: number;
  scheduleCount: number;
  lastEditTime: string;
};

export default function PartyCard({
  partyId,
  partyName,
  memberCount,
  scheduleCount,
  lastEditTime,
}: Props) {
  const navigate = useNavigate();

  const handleClickParty = () => {
    navigate(`/party/${partyId}`);
  };

  console.log(lastEditTime);

  return (
    <section
      className="flex flex-col w-[210px] lg:w-[230px] h-[220px] xl:w-[345px] xl:h-[341px] cursor-pointer relative"
      onClick={handleClickParty}
    >
      <Image
        border
        rounded="rounded-none"
        src=""
        alt={`${partyName} image`}
        width="w-full"
        height="h-[156px] xl:h-[258px]"
        defaultSrc="/images/space/space_card_default.webp"
        hoverEffect={"none"}
      />
      <h2 className="text-xl font-bold pl-2 text-twinworldGray-850 mt-2">
        {partyName}
      </h2>
      <div className="flex flex-col p-2 gap-y-2">
        <div className="flex flex-row gap-x-2">
          <span className="w-[100px]">Member</span>
          <span>{memberCount}</span>
        </div>
        <div className="flex flex-row gap-x-2">
          <span className="w-[100px]">Schedule</span>
          <span>{scheduleCount}</span>
        </div>
        <div className="flex flex-row gap-x-2">
          <span className="w-[100px]">Updated</span>
          <span>{lastEditTime}</span>
        </div>
      </div>
    </section>
  );
}
