import { PartyMember } from "../interface";

class PartyMemberModel {
  nickname;

  userId;

  userThumbnailUrl;

  isPartyOwner;

  id;

  constructor(data: PartyMember) {
    this.id = data.id;
    this.nickname = data.nickname;
    this.userId = data.userId;
    this.userThumbnailUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${data.memberImageId}?size=original&fileType=profile`;
    this.isPartyOwner = data.isPartyOwner;
  }
}

export default PartyMemberModel;
