import { useState, RefObject } from "react";
import { IoIosNotificationsOutline } from "react-icons/io";

import { useOutsideDetectRef } from "../../../hooks/useOutsideDetectRef";
import AlertList from "../../header/AlertList";

export default function AlertIcon() {
  const [isAlertDropdownOpen, setIsAlertDropdownOpen] = useState(false);

  const handleAlertDropdown = () => [setIsAlertDropdownOpen((prev) => !prev)];

  const ref: RefObject<HTMLDivElement> = useOutsideDetectRef(
    () => setIsAlertDropdownOpen(false),
    isAlertDropdownOpen
  );

  return (
    <div
      className="relative cursor-pointer"
      onClick={handleAlertDropdown}
      ref={ref}
    >
      <IoIosNotificationsOutline className="md:text-3xl md:ml-5" />
      <span className="absolute -top-1 left-9">
        <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
          {6}
        </div>
      </span>
      {isAlertDropdownOpen && <AlertList />}
    </div>
  );
}
