import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../contexts/AuthContext";
import Avatar from "../common/Avatar";
import EditIcon from "../common/icons/EditIcon";
import Divider from "../layout/Divider";

type Props = {
  partyId: string;
  partyName: string;
  isPublic: boolean;
  ownerId: string;
  description: string;
  ownerNickName: string;
};

export default function PartyInfo({
  partyId,
  partyName,
  isPublic,
  ownerId,
  description,
  ownerNickName,
}: Props) {
  const { profile } = useAuthContext();
  const navigate = useNavigate();

  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = "/images/space/img_detail_default.webp";
  };

  return (
    <div className="flex flex-col w-full gap-2 xl:flex-row xl:gap-20 px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] xl:portrait:px-0 xl:landscape:px-0">
      <div className="relative flex">
        <img
          alt="spaceImage"
          className="object-cover w-[700px] h-[522px] rounded-[25px] border border-twinworldGray-300"
          src=""
          onError={onError}
        />
        <div className="absolute top-[10px] right-[17px] flex flex-row gap-[17px]">
          <div className="rounded-full border border-black text-semibold bg-white px-4 py-2 text-[16px]">
            {isPublic ? "Public" : "Private"}
          </div>
        </div>
      </div>
      <div className="text-left max-w-[660px] w-[660px] h-[522px] flex flex-col">
        <div className="relative flex items-center w-full gap-3 mt-5 cursor-pointer">
          <Avatar size="lg" src="" />
          <div className="flex flex-col gap-2">
            <h3 className="font-semibold truncate text-[18px] leading-tight text-twinworldGray-700">
              {ownerNickName}
            </h3>
          </div>
        </div>
        <div className="mt-[42px] font-semibold text-[45px] box-border">
          {partyName}
        </div>
        <div className="flex flex-wrap mt-[20px] text-[18px] font-normal leading-[30px] text-twinworldGray-700 text-ellipsis overflow-hidden">
          {description}
        </div>
        {profile?.userId === ownerId && (
          <div className="flex flex-col mt-auto">
            <Divider className="h-1 border-b border-b-twinworldGray-300 mt-[24px] mb-[20px]" />
            <div>
              <button
                className="py-3 px-4 flex items-center justify-center text-[16px] border border-1.5 border-black box-border rounded-full gap-[6px] hover:bg-twinworldPrimary-200 bg-inherit"
                onClick={() => navigate(`/edit/party/${partyId}`)}
              >
                Edit
                <EditIcon />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
