import { RequestFriend } from "../interface";

class FriendRequest {
  nickname;

  serviceName;

  friendId;

  gender;

  userImageUrl;

  userId;

  status;

  id;

  constructor(data: RequestFriend) {
    const {
      nickname,
      serviceName,
      friendId,
      gender,
      friendImageMediaId,
      userId,
      status,
      id,
    } = data;

    this.nickname = nickname;
    this.serviceName = serviceName;
    this.friendId = friendId;
    this.gender = gender;
    this.userImageUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${friendImageMediaId}?size=original&fileType=profile`;
    this.userId = userId;
    this.status = status;
    this.id = id;
  }
}

export default FriendRequest;
