import { ItemDetailsInfo } from "../../components/item/type";
import { FetchList } from "../../types/common";
import { client } from "../../utils/axiosInstance";

import {
  ItemCategory,
  uploadGLBItemInfo,
  UpdateItemInfo,
  ItemInfo,
  uploadOBJItemInfo,
} from "./interface";
import ItemCard, { ItemDataProps } from "./model/ItemCard";
import ItemDetail from "./model/ItemDetail";

const getItemCategoryList = async (): Promise<ItemCategory[]> => {
  const response = await client({
    url: "/media-management/category",
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const category = response.data.data.map(
    (item: { id: string; name: string }) => ({
      idx: item.id,
      name: item.name,
    })
  );

  return [...category];
};

const getItemAICategoryList = async (): Promise<ItemCategory[]> => {
  const response = await client({
    url: "/ai/item/category",
    method: "GET",
  });

  return response.data.map(
    (category: { categoryId: string; categoryName: string }) => ({
      idx: category.categoryId,
      name: category.categoryName,
    })
  );
};

const uploadItem = async (data: uploadGLBItemInfo) => {
  const formData = new FormData();

  formData.append("name", data.name);
  formData.append("categoryId", data.categoryId);
  formData.append("fileType", data.fileType);

  data.type.forEach((value: string) => {
    formData.append("type[]", value);
  });

  formData.append("thumbnail", data.thumbnail);
  formData.append("thumbnailGif", data.thumbnailGif);
  formData.append("thumbnailSprite", data.thumbnailSprite);
  formData.append("media", data.media);

  const response = await client({
    url: "/media-file-upload/upload",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.data;
};

const uploadObj = async (data: uploadOBJItemInfo): Promise<string> => {
  const formData = new FormData();
  formData.append("itemName", data.itemName);
  formData.append("obj", data.obj);
  formData.append("mtl", data.mtl);
  if (data.textures) {
    data.textures.forEach((texture) => {
      formData.append("textures", texture);
    });
  }
  formData.append("thumbnail", data.thumbnail);
  formData.append("categoryId", data.categoryId);
  formData.append("isPublic", `${data.isPublic}`);
  formData.append("isAnimated", `${data.isAnimated}`);
  formData.append("additionalProperties", data.additionalProperties);

  const response = await client({
    url: "/item/upload/obj",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const getInfiniteSearchMyBoxList = async (
  keyword: string,
  take = 24,
  skip = 0
) => {
  const response = await client({
    url: keyword
      ? `/media-management/web/mybox?keyword=${keyword}&take=${take}&skip=${skip}&type=public`
      : `/media-management/web/mybox?&take=${take}&skip=${skip}&type=public`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const itemList: ItemInfo[] = response.data.data.map(
    (item: ItemDataProps) => new ItemCard(item)
  );

  return itemList;
};

const getInfiniteSearchMyItemList = async (
  keyword: string,
  take = 24,
  skip = 0
) => {
  const response = await client({
    url: keyword
      ? `/media-management/web/my-medias?keyword=${keyword}&take=${take}&skip=${skip}&type=public`
      : `/media-management/web/my-medias?&take=${take}&skip=${skip}&type=public`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const itemList: ItemInfo[] = response.data.data.map(
    (item: ItemDataProps) => new ItemCard(item)
  );

  return itemList;
};

const getItemDetails = async (itemId: string): Promise<ItemDetailsInfo> => {
  const response = await client({
    url: `/media-management/web/${itemId}`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const detailData = new ItemDetail(response.data.data);

  return detailData;
};

const updateLikeItem = async (mediaId: string) => {
  const response = await client({
    url: `/media-management/like`,
    method: "POST",
    data: {
      mediaId,
    },
  });

  return response.data;
};

const updateDisLikeItem = async (mediaId: string) => {
  const response = await client({
    url: `/media-management/dislike`,
    method: "POST",
    data: {
      mediaId,
    },
  });

  return response.data;
};

const saveToMyBox = async (itemId: string) => {
  const response = await client({
    url: `/media-management/mybox/${itemId}`,
    method: "POST",
  }).catch((error) => {
    throw error;
  });

  return response.data;
};

const deleteFromMyBox = async (itemId: string) => {
  const response = await client({
    url: `/media-management/mybox/${itemId}`,
    method: "DELETE",
  }).catch((error) => {
    throw error;
  });

  return response.data;
};

const updateMyItemInfo = async (updateItemInfo: UpdateItemInfo) => {
  const { name, categoryId, isPublic, itemId } = updateItemInfo;

  const response = await client({
    url: `/media-management/media/${itemId}`,
    method: "PATCH",
    data: {
      name,
      categoryId,
      isPublic,
    },
  });

  return response.data;
};

const deleteMyItem = async (itemId: string) => {
  const response = await client({
    url: `/media-management/media/${itemId}`,
    method: "DELETE",
  }).catch((error) => {
    throw error;
  });

  return response.data;
};

const getInfiniteSearchItemList = async (
  type: string,
  keyword = "",
  categoryId = "",
  take = 24,
  skip = 0
) => {
  let url = "";

  if (keyword && categoryId === "all") {
    url = `/media-management/web/${type}?keyword=${keyword}&take=${take}&skip=${skip}&extension=glb`;
  } else if (keyword && categoryId !== "all") {
    url = `/media-management/web/${type}?categoryId=${categoryId}&keyword=${keyword}&take=${take}&skip=${skip}&extension=glb`;
  } else if (keyword === "" && categoryId === "all") {
    url = `/media-management/web/${type}?take=${take}&skip=${skip}&extension=glb`;
  } else {
    url = `/media-management/web/${type}?categoryId=${categoryId}&take=${take}&skip=${skip}&extension=glb`;
  }

  const response = await client({
    url,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const itemCardList: ItemInfo[] = response.data.data.map(
    (item: ItemDataProps) => new ItemCard(item)
  );

  return itemCardList;
};

const getInfiniteSearchUserItemList = async (
  userId: string,
  take = 10,
  skip = 0
) => {
  const response = await client({
    url: `/media-management/web/upload-list?uploaderId=${userId}&take=${take}&skip=${skip}&extension=glb`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  const itemCardList: ItemInfo[] = response.data.data.map(
    (item: ItemDataProps) => new ItemCard(item)
  );

  return itemCardList;
};

const getItemList = async ({
  condition = "",
  keyword = "",
  categoryId = "",
  take = 10,
  skip = 0,
  uploaderId,
}: FetchList) => {
  let url = "";

  const tab =
    condition[0] === "#" &&
    `${
      condition.split("#")[1].split("-")[0] === "recommend"
        ? "recommended-list"
        : `${condition.split("#")[1].split("-")[0]}-list`
    }`;

  // keyword가 있을 경우
  if (keyword) {
    url = `/media-management/web/${tab}?keyword=${keyword}&take=${take}&skip=${skip}&extension=glb`;
  }
  // all category (전체 아이템 조회) 인 경우
  else if (categoryId === "all") {
    url = `/media-management/web/${tab}?take=${take}&skip=${skip}&extension=glb`;
  } else if (uploaderId) {
    url = `/media-management/web/${tab}?take=${take}&skip=${skip}&uploaderId=${uploaderId}&extension=glb`;
  }
  // tab item list
  // category item list
  else {
    url = `/media-management/web/${tab}?categoryId=${categoryId}&take=${take}&skip=${skip}&extension=glb`;
  }

  const response = await client({
    url,
    method: "GET",
  });

  const itemCardList = response.data.data.map(
    (item: ItemDataProps) => new ItemCard(item)
  );

  return itemCardList;
};

const itemApi = {
  getItemCategoryList,
  getItemAICategoryList,
  uploadItem,
  getInfiniteSearchMyBoxList,
  getInfiniteSearchMyItemList,
  getItemDetails,
  updateLikeItem,
  updateDisLikeItem,
  saveToMyBox,
  deleteFromMyBox,
  updateMyItemInfo,
  deleteMyItem,
  getItemList,
  getInfiniteSearchItemList,
  getInfiniteSearchUserItemList,
  uploadObj,
};

export default itemApi;
