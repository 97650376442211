import { Fragment } from "react";

import LineIcon from "../common/icons/LineIcon";

type Props = {
  selectedSubMenu: string;
  setSelectedSubMenu: (value: string) => void;
};

type SUB_MENU = {
  key: string;
  label: string;
};

const SUB_MENU_LIST: SUB_MENU[] = [
  { key: "received", label: "Received Request" },
  { key: "sent", label: "Sent Request" },
];

export default function FriendRequestSubMenu({
  selectedSubMenu,
  setSelectedSubMenu,
}: Props) {
  return (
    <ul className="flex items-center h-10">
      {SUB_MENU_LIST.map((menu, index) => (
        <Fragment key={menu.key}>
          <li>
            <button
              className={`px-4 xl:px-[34px] py-2 xl:py-3 capitalize text-[13px] font-medium
                 ${
                   selectedSubMenu === menu.key
                     ? "text-black"
                     : "text-twinworldGray-600"
                 } hover:text-black`}
              type="button"
              onClick={() => setSelectedSubMenu(menu.key)}
            >
              {menu.label}
            </button>
          </li>
          {index !== SUB_MENU_LIST.length - 1 && (
            <li>
              <LineIcon />
            </li>
          )}
        </Fragment>
      ))}
    </ul>
  );
}
