import { useState } from "react";
import { useForm } from "react-hook-form";

import { usePartyAction } from "../../domains/party/hook/usePartyAction";
import getByteSize from "../../utils/getByteSize";
import ToggleButton from "../common/Button/ToggleButton";

const TITLE_MAX_BYTE = 30;
const DESCRIPTION_MAX_BYTE = 100;

type Props = {
  partyId: string;
  partyName: string;
  isPublic: boolean;
  description: string;
};

type Form = {
  name: string;
  description: string;
  isPublic: boolean;
};

export default function PartyEditSection({
  partyId,
  partyName,
  isPublic,
  description,
}: Props) {
  const { usePatchEditParty } = usePartyAction();

  const [name, setName] = useState(partyName);
  const [des, setDes] = useState(description);
  const [isPublicParty, setIsPublicParty] = useState(isPublic);

  const { register, handleSubmit, setValue } = useForm<Form>({
    defaultValues: {
      name: partyName,
      description,
      isPublic,
    },
  });

  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = "/images/space/img_detail_default.webp";
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (getByteSize(e.target.value) <= TITLE_MAX_BYTE) {
      setName(e.target.value);
      setValue("name", e.target.value);
      return;
    }

    setValue("name", name);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (getByteSize(e.target.value) <= DESCRIPTION_MAX_BYTE) {
      setDes(e.target.value);
      setValue("description", e.target.value);
      return;
    }

    setValue("description", description);
  };

  const onSubmit = async () => {
    try {
      await usePatchEditParty({
        partyId,
        name,
        isPublic: isPublicParty,
        description: des,
      });
    } catch (error: any) {
      if (error.response.status === 404 || error.response.status === 400) {
        window.location.href = `/error/${error.response.status}`;
      }
      throw error;
    }
  };

  return (
    <div className="flex flex-col w-full gap-2 xl:flex-row xl:gap-20 md:portrait:px-[32px] md:landscape:px-[58px] xl:portrait:px-0 xl:landscape:px-0 mt-20">
      <div className="relative flex">
        <img
          alt="spaceImage"
          className="object-cover w-[700px] h-[522px] rounded-[25px] border border-twinworldGray-300"
          src=""
          onError={onError}
        />
      </div>
      <form className="text-left max-w-[660px] w-[660px] h-[522px] flex flex-col">
        <div className="flex flex-col items-start justify-between w-full gap-y-2">
          <label
            htmlFor="partyName"
            className="text-[15px] xl:text-[18px] leading-none font-medium"
          >
            Party Name
          </label>
          <div className="flex justify-between items-center w-full py-4 border rounded-full px-[16px] md:px-7 border-twinworldGray-350">
            <input
              className="w-[220px] md:w-[390px] text-[14px] md:text-[16px] font-normal leading-none text-twinworldGray-700 md:text-twinworldGray-600"
              type="text"
              placeholder="Party title"
              defaultValue={partyName}
              {...register("name", {
                required: true,
                onChange: handleNameChange,
              })}
            />
            <span className="text-[12px] md:text-[13px] font-normal text-twinworldGray-600">
              {getByteSize(partyName)} / 30 byte
            </span>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between w-full mb-0 mt-10 gap-y-2">
          <label
            htmlFor="name"
            className="text-[15px] xl:text-[18px] leading-none font-medium"
          >
            Description
          </label>
          <div className="flex flex-col items-end w-full h-[144px] mt-[12px] xl:mt-0 border rounded-xl px-[16px] py-[14px] xl:px-[30px] xl:py-[30px] border-twinworldGray-300 bg-white">
            <textarea
              className="w-full h-full resize-none leading-[24px]"
              {...register("description", {
                onChange: handleDescriptionChange,
              })}
            />
            <span className="mt-2 text-[13px] leading-none text-twinworldGray-600">
              {getByteSize(description)} / {DESCRIPTION_MAX_BYTE} byte
            </span>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between w-full mb-0 mt-10 gap-y-2">
          <label
            htmlFor="ispublic"
            className="text-[15px] xl:text-[18px] leading-none font-medium"
          >
            isPublic
          </label>
          <ToggleButton
            isToggle={isPublicParty}
            handleToggle={() => setIsPublicParty((prev) => !prev)}
          />
        </div>
        <div className="flex gap-[24px] items-center mt-[53px] md:mt-[81px]">
          <button
            className="px-[65px] md:px-[100px] py-[14px] md:py-[21px] text-white md:text-black bg-black md:bg-twinworldPrimary-200 font-medium md:font-semibold text-[16px] rounded-full border-2 border-black md:hover:text-twinworldPrimary-200 md:hover:bg-black"
            onClick={handleSubmit(onSubmit)}
          >
            Save party info
          </button>
        </div>
      </form>
    </div>
  );
}
