import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { usePartyAction } from "../../domains/party/hook/usePartyAction";
import getByteSize from "../../utils/getByteSize";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

import CreatePartySuccessModal from "./CreatePartySuccessModal";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  handleCreatePartySuccessModal: () => void;
};

const DESCRIPTION_MAX_BYTE = 100;

export default function CreatePartyModal({
  isOpen,
  closeModal,
  handleCreatePartySuccessModal,
}: Props) {
  const [isCreatePartySuccess, setIsCreatePartySuccess] = useState(false);

  const { usePostCreateParty } = usePartyAction();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      description: "",
      isPublic: true,
    },
  });

  const watchName = watch("name");

  const isInputValid =
    Object.keys(dirtyFields).length > 0 &&
    Object.keys(errors).length === 0 &&
    watchName;

  // TODO: api 연동 필요.
  const onSubmit = async (data: FieldValues) => {
    if (!isInputValid) {
      return;
    }

    const { name, isPublic, description } = data;
    const signUpInfo = {
      name,
      description,
      isPublic,
    };

    try {
      console.log(signUpInfo);
      await usePostCreateParty(name, isPublic, description);
      handleCreatePartySuccessModal();
    } catch (error) {
      alert("Failed to create party.");
    }
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-[30px] md:mt-[60px] mb-[26px] md:mb-[42px] text-[20px] md:text-[24px] font-semibold">
      Create Party
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[339px] md:max-w-[480px]"
      isOpen={isOpen}
      title={title}
      close={close}
      closeModal={closeModal}
    >
      <div className="w-full h-[214px] md:h-auto">
        <form
          className="flex flex-col items-center gap-4 mx-20 mb-14"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            className={`py-[16px] px-[16px] md:px-7 rounded-full text-[16px] font-normal w-full box-border border ${
              errors?.name
                ? "text-twinworldRed border-twinworldRed"
                : "border-twinworldGray-350 focus:border-twinworldGray-770"
            } `}
            type="text"
            placeholder="Enter party name"
            maxLength={40}
            {...register("name", {
              required: true,
              maxLength: {
                value: 40,
                message: "* Party name should be less than 40 letters",
              },
            })}
          />
          <div className="flex flex-col items-end w-full h-[144px] mt-[12px] xl:mt-0 border rounded-xl px-[16px] py-[14px] xl:p-4 border-twinworldGray-300 bg-white ">
            <textarea
              className="w-full h-full resize-none leading-[24px]"
              {...register("description")}
            />
            <span className="mt-2 text-[13px] leading-none text-twinworldGray-600">
              {watch("description") ? getByteSize(watch("description")) : 0}/
              {DESCRIPTION_MAX_BYTE} byte
            </span>
          </div>
          <div className="flex items-center w-full h-full mt-[26px] ml-[30px]">
            <label htmlFor="isPublic">Is Public</label>
            <input
              type="checkbox"
              {...register("isPublic")}
              className="w-[16px] h-[16px] mr-2.5 cursor-pointer ml-4"
            />
          </div>
          <button
            type="submit"
            className="flex items-center justify-center w-[320px] h-[58px] px-[80px] py-[21px] border-2 border-black bg-twinworldPrimary-200 rounded-full mt-[64px] text-[16px] font-semibold hover:bg-black hover:text-twinworldPrimary-200 
          disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
            disabled={!isInputValid}
          >
            Create Party
          </button>
        </form>
      </div>
    </Modal>
  );
}
