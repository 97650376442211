import {
  InfiniteData,
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";

import { PartyList } from "../../domains/party/interface";
import { useInfiniteScrollRef } from "../../hooks/useInfiniteScrollRef";
import PartyCard from "../card/PartyCard";
import ListWrapper from "../common/ListWrapper";

type Props = {
  myPartyList: InfiniteData<Array<PartyList>> | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
};

export default function MyPartyList({ myPartyList, fetchNextPage }: Props) {
  const { targetRef } = useInfiniteScrollRef({ fetchNextPage });

  return (
    <section className="flex min-w-[339px] w-full px-[18px] md:px-[24px] lg:px-[32px] xl:px-0 flex-col gap-0 mx-auto">
      <h2 className="text-[45px] font-semibold mt-[60px] mb-[20px]">
        My Party List
      </h2>
      <ListWrapper isEmptyData={!myPartyList?.pages[0].length}>
        {myPartyList && myPartyList.pages[0].length > 0 ? (
          myPartyList.pages.map((page) =>
            page.map((data) => (
              <PartyCard
                key={data.id}
                partyId={data.id}
                partyName={data.name}
                memberCount={data.memberCount}
                scheduleCount={data.scheduleCount}
                lastEditTime={data.updatedAt ?? data.createdAt}
              />
            ))
          )
        ) : (
          <div></div>
        )}
      </ListWrapper>
      <div ref={targetRef} />
    </section>
  );
}
