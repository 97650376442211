import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Loading from "../components/common/Loading";
import MainWrapper from "../components/common/MainWrapper";
import TabMenu from "../components/common/menu/TabMenu";
import FriendList from "../components/friends/FriendList";
import RecommendFriendList from "../components/friends/RecommendFriendList";
import RequestFriendList from "../components/friends/RequestFriendList";
import { Menu } from "../components/menu/types";
import { useFriendActions } from "../domains/friend/hook/useFriendActions";

const MENU_LIST: Menu[] = [
  { label: "recommend list" },
  { label: "friend list" },
  { label: "request list" },
];

type Location = {
  state: {
    menu: string;
  };
};

export default function Friends() {
  const { state } = useLocation() as Location;
  const [menu, setMenu] = useState("recommend list");

  const { useRecommendFriendList, useFriendList } = useFriendActions();
  const { isLoading, data: recommendList } = useRecommendFriendList();
  const { data: friendList, fetchNextPage } = useFriendList(24, 0);

  useEffect(() => {
    if (state) {
      setMenu(state.menu);
    }
  }, [state]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MainWrapper>
      <div className="flex flex-col w-full">
        <div className="flex flex-col items-center justify-center w-full gap-8">
          <TabMenu
            menuList={MENU_LIST}
            selectedMenu={menu}
            setSelectedMenu={setMenu}
          />
        </div>
      </div>
      <div className="flex flex-col w-full max-w-[1289px] mx-auto">
        {menu === "recommend list" && recommendList && (
          <RecommendFriendList friendList={recommendList} />
        )}
        {menu === "friend list" && (
          <FriendList friendList={friendList} fetchNextPage={fetchNextPage} />
        )}
        {menu === "request list" && <RequestFriendList />}
      </div>
    </MainWrapper>
  );
}
