import { usePartyAction } from "../../domains/party/hook/usePartyAction";
import useModals from "../hooks/useModals";
import EditScheduleModal from "../modal/EditScheduleModal";

type Schedule = {
  id: string;
  name: string;
  time: string;
};

type Props = {
  schedules: Schedule[];
};

export default function EditScheduleList({ schedules }: Props) {
  const { openModal } = useModals();
  const { useDeleteSchedule } = usePartyAction();

  const handleClickEditBtn = (
    scheduleId: string,
    name: string,
    time: string
  ) => {
    openModal(EditScheduleModal, {
      scheduleId,
      name,
      time,
    });
  };

  // TODO: 추후 dialog 추가
  const handleDeleteSchedule = async (scheduleId: string) => {
    if (window.confirm("Are you sure delete schedule?")) {
      try {
        await useDeleteSchedule(scheduleId);
      } catch (error) {
        alert("Failed delete schedule.");
      }
    }
  };

  return (
    <ul className="w-full no-scrollbar overflow-auto flex flex-col gap-y-4 p-4 mt-10">
      {schedules.map((schedule) => (
        <li
          key={schedule.id}
          className="w-full flex items-center gap-x-10 border-b border-b-twinworldGray-500 p-4"
        >
          <span className="w-[150px]">{schedule.name}</span>
          <span className="ml-auto">{schedule.time}</span>
          {/* <button
            className="cursor-pointer"
            onClick={() =>
              handleClickEditBtn(schedule.id, schedule.name, schedule.time)
            }
          >
            Edit
          </button> */}
          <button
            className="cursor-pointer hover:text-red-600"
            onClick={() => handleDeleteSchedule(schedule.id)}
          >
            Delete
          </button>
        </li>
      ))}
    </ul>
  );
}
