import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";

import { useFriendActions } from "../../domains/friend/hook/useFriendActions";
import { Friend } from "../../domains/friend/interface";
import { useInfiniteScrollRef } from "../../hooks/useInfiniteScrollRef";
import FriendCard from "../card/FriendCard";
import ListWrapper from "../common/ListWrapper";
import NoMatchingResults from "../common/NoMatchingResults";

type FriendCard = Friend & { userImageUrl: string };

type FriendRequestType = Friend & {
  userId: string;
  status: "pending" | "approved";
  createdAt: Date;
  userImageUrl: string;
  id: string;
};

type Props = {
  friendList: InfiniteData<FriendRequestType[]> | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
};

export default function ReceivedRequestFriendList({
  friendList,
  fetchNextPage,
}: Props) {
  const { usePostApproveFriendRequest } = useFriendActions();

  const { targetRef } = useInfiniteScrollRef({ fetchNextPage });

  return (
    <section className="flex min-w-[339px] w-full px-[18px] md:px-[24px] lg:px-[32px] xl:px-0 flex-col gap-0 mx-auto">
      <div className="mt-10">
        <ListWrapper isEmptyData={!friendList?.pages[0].length}>
          {friendList && friendList.pages[0].length > 0 ? (
            friendList?.pages.map((page) =>
              page.map((friend) => (
                <FriendCard
                  key={friend.friendId}
                  friendId={friend.friendId}
                  nickname={friend.nickname}
                  userImageUrl={friend.userImageUrl}
                  serviceName={friend.serviceName}
                  gender={friend.gender}
                >
                  <button
                    className="bg-twinworldGray-650 text-gray-100 px-5 py-3 font-semibold rounded-full mt-2"
                    onClick={() => {
                      usePostApproveFriendRequest(friend.id);
                    }}
                  >
                    Approve
                  </button>
                </FriendCard>
              ))
            )
          ) : (
            <NoMatchingResults />
          )}
        </ListWrapper>
        <div ref={targetRef} />
      </div>
    </section>
  );
}
