import Avatar from "../common/Avatar";

type Member = {
  id: string;
  isPartyOwner: boolean;
  userThumbnailUrl: string;
  nickname: string;
  userId: string;
};

type Props = {
  members: Member[];
};

export default function EditPartyMemberList({ members }: Props) {
  return (
    <ul className="w-full no-scrollbar overflow-auto flex flex-col gap-y-4 p-4 mt-10">
      {members.map((member) => (
        <li
          key={member.userId}
          className="w-full flex items-center gap-x-10 border-b border-b-twinworldGray-500 p-2"
        >
          <Avatar size="lg" src="" />
          <span>{member.nickname}</span>
          <div className="flex ml-auto">
            {member.isPartyOwner ? "Owner" : <button>Resign</button>}
          </div>
        </li>
      ))}
    </ul>
  );
}
