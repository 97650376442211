import { useState } from "react";

import { useFriendActions } from "../../domains/friend/hook/useFriendActions";
import FriendRequestSubMenu from "../menu/FriendRequestSubMenu";

import ReceivedRequestFriendList from "./ReceivedRequestFriendList";
import SentRequestFriendList from "./SentRequestFriendList";

export default function RequestFriendList() {
  const [subMenu, setSubMenu] = useState("received");

  const { useReceivedFriendRequestList, useSentFriendRequestList } =
    useFriendActions();

  const { data: receivedFriendList, fetchNextPage: fetchReceivedFriendList } =
    useReceivedFriendRequestList(24, 0);
  const { data: sentFriendList, fetchNextPage: fetchSentFrieldList } =
    useSentFriendRequestList(24, 0);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col items-center justify-center w-full gap-4 xl:gap-8 mt-[42px] xl:mt-16">
        <FriendRequestSubMenu
          selectedSubMenu={subMenu}
          setSelectedSubMenu={setSubMenu}
        />
      </div>
      {subMenu === "received" ? (
        <ReceivedRequestFriendList
          friendList={receivedFriendList}
          fetchNextPage={fetchReceivedFriendList}
        />
      ) : (
        <SentRequestFriendList
          friendList={sentFriendList}
          fetchNextPage={fetchSentFrieldList}
        />
      )}
    </div>
  );
}
